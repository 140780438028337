import React from "react";
import Header from "./Component/Header/Header";
import WhoAreWe from './Component/WhoAreWe/WhoAreWe';
import Services from '../src/Component/Services/Services';
import ContactForm from './Component/ContactForm/ContactForm';
import WhyChooseUs from './Component/WhyChooseUs/WhyChoose';
import Faq from './Component/Faq/Faq';
import Facebook from './Images/facebook.png'
import Instagram from './Images/instagram.png'
import Whatsapp from './Images/whatsapp.png'
import Youtube from './Images/youtube.png'
import Linkedin from './Images/linkedin.png'
import Twitter from './Images/twitter.png'
import bannerImage from './Images/h3_image4.png'

import './Component/WhoAreWe/WhoAreWe.css';
import Who_Img from './Images/Who-are-we.jpg';

import './Component/WhyChooseUs/WhyChooseUs.css';
// import WhyImg from '../../Images/img3.png';

import './Component/Services/Services.css';

import './Component/ContactForm/ContactForm.css';

import Footer from './Component/Footer/Footer';



import './Home.css';

export default function Home() {

  return (
    <div>
      <Header />
      <div class="banner">
        <div className="background-line"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 banner-left">
              <div class="line"></div>
              <div class="sub-title mt-3">Empowering Digital Growth through Tailored Strategy</div>
              <div class="banner_title mt-3">Welcome to Paperink Media, Where Brand's Story Blossoms into Brilliance</div>
              <div><p class="banner_para mt-5">At Paper Ink Media, we blend the precision of data-driven insights with the innovation of creative marketing to fuel your success.</p></div>
              <div class="mt-5"><a href="#contact"><button class="banner_btn">GET IN TOUCH</button></a></div>
            </div>
            <div class="col-lg-6 bubble-effect">
              <div className="pxl-image-wg"><img src={bannerImage} /></div>
           <div className="bubble-container">
           <div className="bubble"><img src={Facebook} /></div>
           <div className="bubble"><img src={Whatsapp} /></div>
           <div className="bubble"><img src={Instagram} /></div>
           <div className="bubble"><img src={Youtube} /></div>
           <div className="bubble"><img src={Linkedin} /></div>
           <div className="bubble"><img src={Twitter} /></div>
           {/* <div className="bubble"><img src={Facebook} /></div>
           <div className="bubble"><img src={Whatsapp} /></div>
           <div className="bubble"><img src={Instagram} /></div>
           <div className="bubble"><img src={Youtube} /></div> */}
           </div>
               </div>
          </div>
        </div>
      </div>


      <section class="about-section" id="whoarewe">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">WHO WE ARE</span>
                            <h2>Your Mafia for Digital Evolution</h2>
                        </div>
                        <div class="text mt-5">At Paper Ink Media, we are a team of digital marketing enthusiasts dedicated to pushing boundaries and achieving remarkable outcomes for our clients. Our mission is to blend the art of creative storytelling with the science of data-driven marketing strategies. We believe in transparency, collaboration, and measurable results. By diving deep into your brand's objectives and audience insights, we develop tailored campaigns that drive engagement, conversions, and long-term growth.</div>
                        <div class="btn-box mt-5">
                            <a href="#contact" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

               
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>Paperink Media</h2>
                       
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img src={Who_Img} alt=""/></a></figure>
                     
                    </div>
                </div>
              
            </div>
           
        </div>
    </section>


     <div id="whychooseus">
      <section class="chooseus-section d-flex align-items-center" >
        <div class="container">
            <div class="sec-title text-center style-two">
                <h2 class="why-choose-title">Why <span class="why-choose-title-color">Choose Us</span></h2>
            </div>
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_04">
                        <div class="content-box">
                            <div class="single-item">
                                <div class="icon-box">
                                    <div class="bg-layer"></div>
                                    <i class="fas fa-users"></i>
                                </div>
                                <div class="box">
                                    <h4>Proven Results:</h4>
                                    <p>We have a proven track record of delivering tangible results through successful digital marketing campaigns across various platforms & industries.</p>
                                </div>
                            </div>
                            <div class="single-item text-right">
                                <div class="icon-box">
                                    <div class="bg-layer"></div>
                                    <i class="fas fa-box"></i>
                                </div>
                                <div class="box">
                                    <h4>Strategic Approach:</h4>
                                    <p>Our strategic approach involves in-depth research and analysis to tailor customized solutions that maximize impact and ROI for your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 video-column">
                    <div id="video_block_01">
                        <div class="video-inner wow slideInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <div class="pattern-layer"></div>
                            <div class="video-btn">
                                <div class="btn-bg rotate-me"></div>
                                <a href="" class="lightbox-image" data-toggle="modal" data-target="#myModal"><i class="fas fa-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <div class="modal fade" id="myModal">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="modal-body">
         <iframe width="100%" height="315" src="https://www.youtube.com/embed/WqUaM-ZueXU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  </div>


  <section id="service">
  <div>
<div class="feat bg-gray pt-5 pb-5 service">
    <div class="container">
      <div class="row ">
        <div class="section-head col-sm-12">
          <h4><span>Our</span> Services</h4>
          <p>Explore our comprehensive range of digital marketing services designed to elevate your brand and drive results. From SEO and social media management to captivating content creation and innovative campaigns, we have the expertise to fuel your online success. Let's transform your digital strategy together.</p>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
            <h6>Wordpress Design</h6>
            <p>Create captivating websites with our custom WordPress designs that reflect your brand and engage users.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_two"><i class="fa fa-anchor"></i></span>
            <h6>Performance Marketing</h6>
            <p>Optimize campaigns for maximum ROI using data-driven strategies tailored to your business goals.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
            <h6>SEO</h6>
            <p>Boost visibility and drive organic traffic with our proven SEO tactics and optimization techniques.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_four"><i class="fa fa-database"></i></span>
            <h6>social Media Marketing</h6>
            <p>Build brand awareness and engage your audience with targeted social media strategies.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_five"><i class="fa fa-upload"></i></span>
            <h6>Google Analytics Services</h6>
            <p>Gain insights and track performance with our expert Google Analytics services.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_six"><i class="fa fa-camera"></i></span>
            <h6>UI/UX Design</h6>
            <p>Enhance user experience and interface design for intuitive, engaging digital interactions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
  </section>


  <section class="contact-sec sec-pad" id="contact">
  <div class="container">
    <div class="row">
    <div class="col-md-6 contact">
    <div>
        <h2>Experience Real Results</h2>
        <p>Partner with Thrive Internet Marketing Agency and scale your business.</p>
        </div>
        <form action="#" class="contFrm" method="POST">
          {/* <div class="container-fluid"> */}
          <div class="row mt-5">
            <div class="col-sm-6">
              <input type="text" name="firstname" placeholder="First Name" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="text" name="lastname" placeholder="Last Name" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="text" name="company" placeholder="Company/Organization" class="inptFld" required />
            </div><div class="col-sm-6">
              <input type="text" name="ebsite" placeholder="Website" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="email" name="email" placeholder="Email Address" class="inptFld" required />
            </div>

            <div class="col-sm-6">
              <input type="tel" name="phone" placeholder="Phone Number" class="inptFld" required />
            </div>

            {/* <div class="col-sm-6">
              <input type="text" name="sub" placeholder="Subject" class="inptFld" required />
            </div> */}

            <div class="col-12">
              <textarea class="inptFld" rows="" cols="" placeholder="Your Message..." required></textarea>
            </div>

            <div class="col-12">
              <input type="submit" name="submit" value="SUBMIT" class="inptBtn" />
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
      <div class="col-md-6">
        <div class="timeline">
        <div>
        <h2>Ready to Kickstart Your Next Project?</h2>
        <p>Kickstart your Brand's Digital Success in 3 Steps: </p>
        </div>
        <section class="timeline-area">
        <div class="timeline-content">
          <h3>Contact us :</h3>
          <p>Reach Out to Us for Inquiries, Collaborations, or Just to Say Hello! Let's Connect and Discuss Your Next Project.</p>
        </div>
        <div class="timeline-content">
        <h3>Launch Target Campaigns:</h3>
          <p>Reach your audience effectively with our targeted campaign strategies. We'll help you launch tailored marketing initiatives to achieve your business goals efficiently.</p>
        </div>
        <div class="timeline-content">
        <h3>Achieve Sustainable Growth :</h3>
          <p>Drive sustainable growth with our strategic solutions. Let's collaborate to implement effective strategies that ensure continuous success for your business.</p>
        </div>
        </section>
        </div>
      </div>
    </div>
  </div>
</section>

      {/* <WhoAreWe/> */}
      {/* <WhyChooseUs /> */}
      {/* <Services /> */}
      {/* <Testimonials /> */}
      {/* <Blog /> */}
      {/* <ContactForm/> */}
      <Faq />
      <Footer /> 
      
      
      
      
      
    
    
    
     
    </div>
  )
}