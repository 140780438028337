import './Footer.css';
import Footer_logo from '../../Images/logo white.png';

export default function Footer(){
    return(
        <div>
  <footer class="footer">
  	 <div class="container">
  	 	<div class="row">
  	 		<div class="footer-col">
           <a class="footer-logo" href="#"><img src={Footer_logo} class="f-logo"/></a>
           <p class="footer-para mt-3">Paper Ink Media Internet Marketing Agency is a full-service digital marketing agency. Cultivate, Captivate, and Convert Leads Online for Lasting Success with Paper Ink Media.</p>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>Quick links</h4>
  	 			<ul>
  	 				<li><a href="#">Home</a></li>
  	 				<li><a href="#whychooseus">Why choose us</a></li>
  	 				<li><a href="#service">services</a></li>
  	 				<li><a href="#">Blog</a></li>
  	 				<li><a href="#contact">Contact</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>Company</h4>
  	 			<ul>
  	 				<li><a href="#">privacy - policy</a></li>
  	 				<li><a href="#">Terms & Conditions</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>follow us</h4>
  	 			<div class="social-links">
  	 				<a href="https://www.facebook.com/paperinkmedia1"><i class="fab fa-facebook-f"></i></a>
  	 				<a href="https://twitter.com/paperinkmedia"><i class="fab fa-twitter"></i></a>
  	 				<a href="https://www.instagram.com/paper_inkmedia/"><i class="fab fa-instagram"></i></a>
  	 				<a href="https://www.linkedin.com/company/97191329/"><i class="fab fa-linkedin-in"></i></a>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
	 <div>
		
	 </div>
  </footer>
  <div class="bottom-footer">
<div>Copyright © 2024 paperinkmedia.com | Powered by <a href="paperinkmedia.com" class="copywrites">paperinkmedia.com</a></div>
  </div>
        </div>
    )
}