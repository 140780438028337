
import './Services.css';

export default function WhoAreWe() {
    return (
<div>
<div class="feat bg-gray pt-5 pb-5 service">
    <div class="container">
      <div class="row ">
        <div class="section-head col-sm-12">
          <h4><span>Our</span> Services</h4>
          <p>Explore our comprehensive range of digital marketing services designed to elevate your brand and drive results. From SEO and social media management to captivating content creation and innovative campaigns, we have the expertise to fuel your online success. Let's transform your digital strategy together.</p>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
            <h6>Wordpress Design</h6>
            <p>Create captivating websites with our custom WordPress designs that reflect your brand and engage users.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_two"><i class="fa fa-anchor"></i></span>
            <h6>Performance Marketing</h6>
            <p>Optimize campaigns for maximum ROI using data-driven strategies tailored to your business goals.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
            <h6>SEO</h6>
            <p>Boost visibility and drive organic traffic with our proven SEO tactics and optimization techniques.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_four"><i class="fa fa-database"></i></span>
            <h6>social Media Marketing</h6>
            <p>Build brand awareness and engage your audience with targeted social media strategies.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_five"><i class="fa fa-upload"></i></span>
            <h6>Google Analytics Services</h6>
            <p>Gain insights and track performance with our expert Google Analytics services.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_six"><i class="fa fa-camera"></i></span>
            <h6>UI/UX Design</h6>
            <p>Enhance user experience and interface design for intuitive, engaging digital interactions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
    )
}
