import './Header.css';
import logo from '../../Images/logo.png';

import React from 'react';
import { Link } from 'react-router-dom';


export default function Header() {
    return (
        <div>
            <nav className="topbar navbar-expand-lg">
                <div className="container d-flex">
                    <div className="collapse navbar-collapse justify-content-start" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/email" className="topbar-link"><i className="fa-solid fa-envelope"></i> {"\t"} &nbsp;contact@paperinkmedia.com</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact-number" className="topbar-link"><i className="fa-solid fa-phone"></i> {"\t"} &nbsp;+91 7904067760</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="topbar-icon" href="https://www.facebook.com/paperinkmedia1"><i className="fa-brands fa-square-facebook"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="topbar-icon" href="https://www.instagram.com/paper_inkmedia/"><i className="fa-brands fa-square-instagram"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="topbar-icon" href="https://www.linkedin.com/company/97191329/"><i className="fa-brands fa-linkedin"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg  bg-light">
                <div className="container">

                    <Link to="/" className="navbar-brand"><img src={logo} className="logo" alt="Logo"/></Link>
                    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/" className="nav-link active" aria-current="page">HOME</Link>
                            </li>
                            <li className="nav-item">
                                <a href="#whoarewe" className="nav-link">WHO ARE WE</a>
                            </li>
                            <li className="nav-item">
                            <a href="#whychooseus" className="nav-link">WHY CHOOSE US</a>
                            </li>
                            <li className="nav-item">
                                <a href="#service" className="nav-link">SERVICE</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Blog" className="nav-link">BLOG</a>
                            </li>
                            <li className="nav-item">
                            <a href="#contact" className="nav-link">CONTACT</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}























