import './Faq.css';

export default function Faq(){
    return(
        <div>

<div class="accordion">
    <h2>Frequently Asked Questions</h2>
    <div class="accordion-item">
        <input type="checkbox" id="accordion1"/>
        <label for="accordion1" class="accordion-item-title"><span class="icon"></span>What services does Paper Ink Media offer</label>
        <div class="accordion-item-desc">Paper Ink Media offers a range of digital marketing services including WordPress design, SEO, social media marketing, performance marketing, Google Analytics services, and UI/UX design.</div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion2"/>
        <label for="accordion2" class="accordion-item-title"><span class="icon"></span>How can Paper Ink Media help my business grow?</label>
        <div class="accordion-item-desc">We can help your business grow by implementing tailored digital strategies to boost online visibility, engage your target audience, drive traffic, and increase conversions.</div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion3"/>
        <label for="accordion3" class="accordion-item-title"><span class="icon"></span>How do I get started with Paper Ink Media?</label>
        <div class="accordion-item-desc">Getting started is easy! Simply reach out to us via our contact form or email, and our team will schedule a consultation to discuss your goals and propose the best solutions for your business.</div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion4"/>
        <label for="accordion4" class="accordion-item-title"><span class="icon"></span>What makes Paper Ink Media different from other digital marketing agencies?</label>
        <div class="accordion-item-desc">At Paper Ink Media, we combine data-driven insights with creative expertise to deliver impactful results. Our personalized approach and dedication to client success set us apart.</div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion5"/>
        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How does Paper Ink Media measure success?</label>
        <div class="accordion-item-desc">We measure success through key performance indicators (KPIs) such as increased website traffic, improved search engine rankings, higher conversion rates, and overall business growth.</div>
    </div>

</div>
        </div>
    )
}