import './App.css';
import Home from './Home';
// import Banner from '../src/Component/Banner/Banner';
import WhoAreWe from './Component/WhoAreWe/WhoAreWe';
import Testimonials from '../src/Component/Testimonials/Testimonials';
import Services from '../src/Component/Services/Services';
import ContactForm from './Component/ContactForm/ContactForm';
import WhyChoose from './Component/WhyChooseUs/WhyChoose';
import Faq from './Component/Faq/Faq';
import Blog from './Component/Blog/Blog';
import Footer from './Component/Footer/Footer';

import{ BrowserRouter, Routes, Route, Link} from 'react-router-dom';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        {/* <Route path='/Banner' element={<Banner/>}/> */}
        <Route path='/WhoAreWe' element={<WhoAreWe/>}/>
        <Route path='/ContactForm' element={<ContactForm/>}/>
        <Route path='/Testimonials ' element={<Testimonials />}/>
        <Route path='/Services ' element={<Services />}/>
        <Route path='/WhyChoose ' element={<WhyChoose />}/>
        <Route path='/Faq ' element={<Faq />}/>
        <Route path='/Blog ' element={<Blog />}/>
        <Route path='/Footer ' element={<Footer />}/>

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
