import './WhyChooseUs.css';
import WhyImg from '../../Images/img3.png';

export default function Header() {
    return (
        <div>
                <section class="chooseus-section d-flex align-items-center">
        <div class="container">
            <div class="sec-title text-center style-two">
                <h2 class="why-choose-title">Why Choose Us</h2>
            </div>
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_04">
                        <div class="content-box">
                            <div class="single-item">
                                <div class="icon-box">
                                    <div class="bg-layer"></div>
                                    <i class="fas fa-users"></i>
                                </div>
                                <div class="box">
                                    <h4>Proven Results:</h4>
                                    <p>We have a proven track record of delivering tangible results through successful digital marketing campaigns across various platforms & industries.</p>
                                    {/* <a href="#"><i class="fas fa-arrow-right"></i>More Details</a> */}
                                </div>
                            </div>
                            <div class="single-item text-right">
                                <div class="icon-box">
                                    <div class="bg-layer"></div>
                                    <i class="fas fa-box"></i>
                                </div>
                                <div class="box">
                                    <h4>Strategic Approach:</h4>
                                    <p>Our strategic approach involves in-depth research and analysis to tailor customized solutions that maximize impact and ROI for your business.</p>
                                    {/* <a href="#">More Details<i class="fas fa-arrow-left"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 video-column">
                    <div id="video_block_01">
                        <div class="video-inner wow slideInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
                        {/* <div class="video-inner wow slideInRight" data-wow-delay="00ms" data-wow-duration="1500ms" style="background-image: url(https://i.ibb.co/Lz7ZrL1/video-bg.jpg);"> */}
                            <div class="pattern-layer"></div>
                            {/* <div class="pattern-layer" style="background-image: url(https://i.ibb.co/1qq5bKr/cube-shapes.png);"></div> */}
                            <div class="video-btn">
                                <div class="btn-bg rotate-me"></div>
                                {/* <div class="btn-bg rotate-me" style="background-image: url(https://i.ibb.co/kKmvKRY/btn-icon-bg.png);"></div> */}
                                <a href="" class="lightbox-image" data-toggle="modal" data-target="#myModal"><i class="fas fa-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <div class="modal fade" id="myModal">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="modal-body">
         <iframe width="100%" height="315" src="https://www.youtube.com/embed/WqUaM-ZueXU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         
        </div>
        
        
      </div>
    </div>
  </div>
        </div>
    );
}