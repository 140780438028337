
import './WhoAreWe.css';
import Who_Img from '../../Images/Who-are-we.jpg';

export default function WhoAreWe() {
    return(
        <div>
            <section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">WHO WE ARE</span>
                            <h2>Your Mafia for Digital Evolution</h2>
                        </div>
                        <div class="text mt-5">At Paper Ink Media, we are a team of digital marketing enthusiasts dedicated to pushing boundaries and achieving remarkable outcomes for our clients. Our mission is to blend the art of creative storytelling with the science of data-driven marketing strategies. We believe in transparency, collaboration, and measurable results. By diving deep into your brand's objectives and audience insights, we develop tailored campaigns that drive engagement, conversions, and long-term growth.</div>
                      {/* <div class="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div> */}
                        <div class="btn-box mt-5">
                            <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

                {/* <!-- Image Column --> */}
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>Paperink Media</h2>
                        {/* <span>Web Developer</span> */}
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img src={Who_Img} alt=""/></a></figure>
                     
                    </div>
                </div>
              
            </div>
        </div>
    </section>
        </div>
    )
}