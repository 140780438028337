import './ContactForm.css';

export default function ContactForm(){
    return(
        <div>
            
      <section class="contact-sec sec-pad">
  <div class="container">
    <div class="row">
    <div class="col-md-6 contact">
    <div>
        <h2>Experience Real Results</h2>
        <p>Partner with Thrive Internet Marketing Agency and scale your business.</p>
        </div>
        <form action="#" class="contFrm" method="POST">
          {/* <div class="container-fluid"> */}
          <div class="row mt-5">
            <div class="col-sm-6">
              <input type="text" name="firstname" placeholder="First Name" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="text" name="lastname" placeholder="Last Name" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="text" name="company" placeholder="Company/Organization" class="inptFld" required />
            </div><div class="col-sm-6">
              <input type="text" name="ebsite" placeholder="Website" class="inptFld" required />
            </div>
            <div class="col-sm-6">
              <input type="email" name="email" placeholder="Email Address" class="inptFld" required />
            </div>

            <div class="col-sm-6">
              <input type="tel" name="phone" placeholder="Phone Number" class="inptFld" required />
            </div>

            {/* <div class="col-sm-6">
              <input type="text" name="sub" placeholder="Subject" class="inptFld" required />
            </div> */}

            <div class="col-12">
              <textarea class="inptFld" rows="" cols="" placeholder="Your Message..." required></textarea>
            </div>

            <div class="col-12">
              <input type="submit" name="submit" value="SUBMIT" class="inptBtn" />
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
      <div class="col-md-6">
        <div class="timeline">
        <div>
        <h2>Ready to Kickstart Your Next Project?</h2>
        <p>Kickstart your Brand's Digital Success in 3 Steps: </p>
        </div>
        <section class="timeline-area">
        <div class="timeline-content">
          <h3>Contact us :</h3>
          <p>Reach Out to Us for Inquiries, Collaborations, or Just to Say Hello! Let's Connect and Discuss Your Next Project.</p>
        </div>
        <div class="timeline-content">
        <h3>Launch Target Campaigns:</h3>
          <p>Reach your audience effectively with our targeted campaign strategies. We'll help you launch tailored marketing initiatives to achieve your business goals efficiently.</p>
        </div>
        <div class="timeline-content">
        <h3>Achieve Sustainable Growth :</h3>
          <p>Drive sustainable growth with our strategic solutions. Let's collaborate to implement effective strategies that ensure continuous success for your business.</p>
        </div>
        </section>
        </div>
      </div>
    </div>

    {/* <div class=""style="text-align: center; margin-top: 20px;">
      <p> Copyright &copy;
        <script>
          document.write(new Date().getFullYear());
        </script>
        All rights reserved | Ajeet
      </p>
    </div> */}

  </div>
</section>
     
        </div>
    )
}


