import './Testimonials.css';
import testimage from '../../Images/testimonials.jpg';
export default function Testi() {
  

  return (
    <div>
      <section class="tesimonial-section">
      <div className="container">
      <div className="testi-title-section">
                        <div className="testi-title-section-main">
                            Testimonials
                        </div>
                        <div className="testi-title-section-desc">
                        Discover testimonials from satisfied clients sharing their experiences and results. Hear their success stories and why they choose us for their digital needs.
                        </div>
                    </div>
                    </div>
      <div className="container mt-5">
      <div id="carouselExampleIndicators" class="carousel slide">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Ravi Tea Shop </div>
                <div class="testi-comment mt-2">
                <p>"Thanks to Paper Ink Media's exceptional local SEO service, our tea outlet's online visibility skyrocketed! We've seen a significant increase in online visibilty, leads and customers. Highly recommend their expertise for boosting local business presence."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='carousal-box'>
                <div><img src={testimage} class="clr-img mt-3"/></div>
                <div class="testi-name mt-2">Alex</div>
                <div class="testi-comment mt-2">
                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry."</p>
                </div>
                <div class="testi-rating">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </section>

    </div>
  )
}